import styled from 'styled-components';

import { Color, Label as LabelBase, PrimaryButton } from '../../theme';

export const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Color.Gray};
  color: ${Color.White};
  flex-direction: column;
  padding: 20px;
`;

export const Label = styled(LabelBase)`
  text-align: center;
  margin-bottom: 30px;
`;

export const Button = styled(PrimaryButton)`
  width: auto;
`;

export enum Color {
  White = '#fff',
  Black = '#000',
  Gray = '#343a40',
  LightGray = '#eee',
  Blue = '#007bff',
  Red = '#ff0000',
  LightRed = '#f69697',
  Green = '#059142',
  LightGreen = '#cefad0',
}

import {
  collection,
  doc,
  getDoc,
  getDocs,
} from 'firebase/firestore';
import { Dayjs } from 'dayjs';

import { PoiInterface, ResultDataInterface } from '../interfaces';
import { Collection, db } from '../firebase';

export const loadPoiList = async (onFinish: (poisData: PoiInterface[]) => void) => {
  const poiData = await getDocs(collection(db, Collection.Pois));
  const pois: PoiInterface[] = [];

  poiData.forEach((poi) => {
    pois.push({ id: poi.id, name: poi.data().name });
  });

  onFinish(pois);
};

export const loadGoals = async (
  chosenDate: Dayjs,
  onFinish: (goalsData: ResultDataInterface) => void,
) => {
  const firstOfMonth = chosenDate.startOf('month').unix();
  const goalsRef = doc(db, Collection.Goals, firstOfMonth.toString());
  const goalsDoc = await getDoc(goalsRef);

  if (goalsDoc.exists()) {
    onFinish(goalsDoc.data());
  }
};

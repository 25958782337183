import styled from 'styled-components';

import { Color } from '../../theme';

export const Container = styled.div`
  padding-top: 50px;
  padding-bottom: 20px;
  text-align: center;
`;

export const Separator = styled.hr`
  margin-bottom: 20px;
`;

export const Text = styled.small`
  font-style: italic;
`;

export const ExternalLink = styled.a`
  text-decoration: none;
  color: ${Color.Blue};

  :hover {
    text-decoration: underline;
  }
`;

import {
  ChangeEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { MenuItem, SelectChangeEvent, ThemeProvider } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';

import { AuthContext } from '../../contexts/auth/auth.context';
import { loadPoiList } from '../../api/common';
import { convertReportValuesToNumbers, convertReportValuesToString, initFormValues } from '../../utils';
import { UserRole, Path } from '../../enums';
import { ReportInterface, PoiInterface } from '../../interfaces';
import { Dropdown, muiDatePickerTheme } from '../../theme';
import { ReportValuesKeys, dateFormat, dateWithHourFormat } from '../../constants';
import { maxEditDays } from '../reportsDetails/reportsDetails.constants';

import { loadReport, editReport } from './editReport.api';
import {
  Container,
  Header,
  Inputs,
  StyledInput,
  StyledButton,
  DatePickerWrapper,
} from './editReport.styled';

export const EditReport = () => {
  const navigate = useNavigate();
  const { reportId } = useParams();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [chosenDate, setChosenDate] = useState(
    dayjs(new Date().setHours(0, 0, 0, 0)),
  );
  const [pois, setPois] = useState<PoiInterface[]>([]);
  const [pos, setPos] = useState('');
  const [report, setReport] = useState<ReportInterface>();
  const [sendButtonDisabled, setSendButtonDisabled] = useState(true);
  const [values, setValues] = useState(initFormValues);
  const canEditReports = user?.role === UserRole.Moderator || user?.role === UserRole.Superadmin;

  const onReportLoaded = (reportData: ReportInterface) => {
    setReport(reportData);
    setChosenDate(dayjs(reportData.timestamp * 1000));
  };

  useEffect(() => {
    if (canEditReports && reportId) {
      loadPoiList(setPois);
      loadReport(reportId, onReportLoaded);
    } else {
      navigate(Path.Home);
    }
  }, []);

  useEffect(() => {
    if (report) {
      setPos(report.poiId);
      setValues(convertReportValuesToString(report));
    }
  }, [report]);

  const handlePosChange = ({ target }: SelectChangeEvent<unknown>) => {
    setPos(target.value as string);
    setSendButtonDisabled(false);
  };

  const handleInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [target.name]: Number(target.value) < 0 ? 0 : target.value,
    });
    setSendButtonDisabled(false);
  };

  const handleDateChange = (newValue: Dayjs | null) => {
    if (newValue) {
      const newChosenDate = dayjs(newValue)
        .set('hour', chosenDate.hour())
        .set('minute', chosenDate.minute())
        .set('second', chosenDate.second());
      setChosenDate(newChosenDate);
    } else {
      setChosenDate(dayjs(new Date().setHours(0, 0, 0, 0)));
    }
    setSendButtonDisabled(false);
  };

  const saveReport = () => {
    if (!report) return;

    const newData = {
      ...convertReportValuesToNumbers(values),
      id: reportId,
      poiId: pos,
      timestamp: chosenDate.unix(),
      user: report?.user,
      editTimestamp: dayjs(new Date()).unix(),
      editUser: user?.id || '',
    };

    setSendButtonDisabled(true);

    if (user?.id) {
      editReport(newData, () => {
        navigate(Path.EditReports);
      });
    } else {
      alert(t('addReport.logInAgain'));
      navigate(Path.Home);
    }
  };

  if (!report) return null;

  return (
    <Container>
      <Link to={Path.EditReports}>{t('editReports.goBack')}</Link>
      <Header>
        <div>
          {`${t('editReports.createdAt')} ${dayjs.unix(report.timestamp).format(dateWithHourFormat)}`}
        </div>
        <div>
          {`${t('editReports.by')} ${report.user}`}
        </div>
      </Header>
      <Inputs>
        <DatePickerWrapper>
          <ThemeProvider theme={muiDatePickerTheme}>
            <DesktopDatePicker
              label={t('editReports.changeDate')}
              format={dateFormat}
              minDate={maxEditDays}
              value={chosenDate}
              onChange={handleDateChange}
              disableFuture
              slotProps={{
                textField: {
                  InputProps: {
                    onKeyDown: (e) => e.preventDefault(),
                    disabled: true,
                  },
                },
              }}
            />
          </ThemeProvider>
        </DatePickerWrapper>
        <Dropdown
          value={pos}
          onChange={handlePosChange}
          disabled={!pois.length}
        >
          {pois.map((poi) => (
            <MenuItem key={poi.id} value={poi.id}>
              <b>{poi.name.toUpperCase()}</b>
            </MenuItem>
          ))}
        </Dropdown>
        {ReportValuesKeys.map((key) => (
          <StyledInput
            key={key}
            label={t(`addReport.${key}`)}
            variant="outlined"
            placeholder={t('addReport.inputPlaceholder')}
            type="number"
            value={values[key]}
            name={key}
            onChange={handleInputChange}
            onWheel={(event) => event.target instanceof HTMLElement && event.target.blur()}
            InputProps={{
              inputProps: { min: 0 },
            }}
          />
        ))}
      </Inputs>
      <StyledButton
        variant="contained"
        color="success"
        disabled={Object.values(values).some((value) => !value) || sendButtonDisabled}
        onClick={saveReport}
      >
        {t('editReports.save')}
      </StyledButton>
    </Container>
  );
};

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { DeletePopup } from '../../../../components/deletePopup/deletePopup.component';
import { Path } from '../../../../enums';
import { ReportValuesKeys, dateWithHourFormat } from '../../../../constants';
import { Small } from '../../../../theme';

import {
  Container,
  Text,
  Bold,
  Label,
  Options,
  DeleteButton,
  StyledLink,
} from './report.styled';
import { ReportProps } from './report.types';

export const Report = ({ data, onDelete }: ReportProps) => {
  const { t } = useTranslation();
  const [deletePopupVisible, setDeletePopupVisible] = useState(false);

  const onDeleteConfirm = () => {
    onDelete();
    setDeletePopupVisible(false);
  };

  return (
    <Container>
      <Label>
        {t('editReports.poi')}
        {' '}
        <Bold>{data.poiId}</Bold>
      </Label>
      {ReportValuesKeys.map((key, index) => (
        <Text key={key}>
          {t(`editReports.${key}`)}
          {' '}
          <Bold className={index < 5 ? 'highlighted' : ''}>
            {data[key]}
          </Bold>
        </Text>
      ))}
      <div>
        <Small>
          {`${t('editReports.createdAt')} ${dayjs.unix(data.timestamp).format(dateWithHourFormat)}`}
        </Small>
      </div>
      <div>
        <Small>{`${t('editReports.by')} ${data.user}`}</Small>
      </div>
      <Options>
        <DeleteButton onClick={() => setDeletePopupVisible(true)}>
          {t('editReports.delete')}
        </DeleteButton>
        <StyledLink to={`${Path.EditReports}/${data.id}`}>{t('editReports.edit')}</StyledLink>
      </Options>
      {deletePopupVisible && (
        <DeletePopup
          text={t('editReports.deleteReport')}
          onConfirm={onDeleteConfirm}
          onCancel={() => setDeletePopupVisible(false)}
        />
      )}
    </Container>
  );
};

import styled from 'styled-components';

import { Color, Input, PrimaryButton } from '../../theme';

export const Container = styled.div``;

export const Inputs = styled.div`
  margin-top: 30px;
`;

export const StyledInput = styled(Input)`
  && {
    margin-top: 15px;
  }
`;

export const Button = styled(PrimaryButton)`
  && {
    margin-top: 20px;
  }
`;

export const Checkmark = styled.span`
  color: ${Color.Green};
  margin-left: 10px;
`;

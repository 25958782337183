import styled from 'styled-components';

export const Container = styled.div``;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  padding: 50px 20px 20px;
`;

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Path } from '../../enums';

export const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(Path.Home);
  }, []);

  return null;
};

import { Button, TextField, Select } from '@mui/material';
import styled from 'styled-components';

export const Input = styled(TextField)`
  width: 100%;
`;

export const PrimaryButton = styled(Button)`
  width: 100%;

  && {
    font-size: 16px;
  }
`;

export const Dropdown = styled(Select)`
  width: 100%;
`;

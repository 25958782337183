import { PoiResultInterface, ReportInterface } from '../../interfaces';

export const updateResultsData = (poiResults: PoiResultInterface, newReport: ReportInterface) => (
  Object.fromEntries(
    Object.entries(poiResults).map(
      ([key, value]) => (
        [key, key === 'poiId' ? value : value + newReport[key as keyof PoiResultInterface]]
      ),
    ),
  )
);

export enum ReportValueKey {
  TEL_MIN = 'telMin',
  ODK = 'odk',
  VAS = 'vas',
  KRED = 'kred',
  POZ_TOTAL = 'pozTotal',
  ZAT_TOTAL = 'zatTotal',
  TEL = 'tel',
  ISP = 'isp',
  CALL = 'call',
}

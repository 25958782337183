import { DocumentData, QuerySnapshot } from 'firebase/firestore';

import { ReportInterface, ResultDataInterface, PoiResultInterface } from '../../interfaces';
import { ReportValuesKeys } from '../../constants';

const createResultsData = (poiId: string, poiReports: ReportInterface[]) => (
  ReportValuesKeys.reduce((data, key) => ({
    ...data,
    [key]: poiReports.reduce((acc, report) => acc + (report[key] ?? 0), 0),
  }), { poiId } as PoiResultInterface)
);

export const convertResultsDataToObject = (
  poiData: QuerySnapshot<DocumentData>,
  reports: ReportInterface[],
) => {
  const resultsData: ResultDataInterface = {};

  poiData.forEach((poi) => {
    const poiReports = reports.filter((report) => report.poiId === poi.id);
    resultsData[poi.id.toLowerCase()] = createResultsData(poi.id, poiReports);
  });

  return resultsData;
};

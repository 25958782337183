import styled from 'styled-components';
import { Typography } from '@mui/material';

import { Color, Input, PrimaryButton } from '../../theme';

export const Container = styled.div``;

export const StyledTypography = styled(Typography)`
  && {
    margin-bottom: 30px;
  }
`;

export const StyledInput = styled(Input)`
  && {
    margin-bottom: 10px;
  }
`;

export const UserItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const UserId = styled.div``;

export const AddUserButton = styled(PrimaryButton)`
  && {
    margin-bottom: 40px;
  }
`;

export const DeleteButton = styled.button`
  margin: 0 0 0 20px;
  padding: 0;
  background-color: transparent;
  border: none;
  color: ${Color.Red};
  font-style: italic;
  cursor: pointer;
`;

export const Separator = styled.hr`
  margin: 20px 0;
`;

import { Buffer } from 'exceljs';
import i18n from 'i18next';

import { ReportInterface } from '../../interfaces';
import { ReportValuesKeys } from '../../constants';

export const downloadExcelFile = (data: Buffer, reportsDate: string) => {
  const blob = new Blob([data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const url = window.URL.createObjectURL(blob);
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = `raport-${reportsDate}.xlsx`;
  anchor.click();
  window.URL.revokeObjectURL(url);
};

export const createColumnsTemplateData = () => {
  const columnsTemplate = ReportValuesKeys.map((key) => ({
    header: i18n.t(`reports.${key}`), key, width: 5,
  }));

  return [
    { header: i18n.t('reports.poi'), key: 'poiId', width: 5 },
    ...columnsTemplate,
  ];
};

export const createSumRowData = (todayReports: ReportInterface[]) => (
  ReportValuesKeys.reduce((data, key) => ({
    ...data,
    [key]: todayReports.reduce(
      (acc, report) => acc + (report[key] ?? 0),
      0,
    ),
  }), { poiId: todayReports.length })
);

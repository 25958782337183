import {
  ChangeEvent,
  Fragment,
  KeyboardEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { DeletePopup } from '../../components/deletePopup/deletePopup.component';
import { AuthContext } from '../../contexts/auth/auth.context';
import { UserInterface } from '../../interfaces';
import { UserRole, Path } from '../../enums';

import {
  Container,
  StyledTypography,
  StyledInput,
  UserItem,
  UserId,
  AddUserButton,
  DeleteButton,
  Separator,
} from './users.styled';
import { deleteUser, loadUsers, saveUser } from './users.api';

export const Users = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [users, setUsers] = useState<UserInterface[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [userToDelete, setUserToDelete] = useState<string | null>(null);
  const canEditUsers = user?.role === UserRole.Moderator || user?.role === UserRole.Superadmin;

  useEffect(() => {
    if (canEditUsers) {
      loadUsers(setUsers);
    } else {
      navigate(Path.Home);
    }
  }, []);

  const validateEmail = (email: string) => (
    email.toLowerCase().match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
  );

  const handleInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setInputValue(target.value);
  };

  const handleAcceptNewUser = () => {
    setInputValue('');
    setSaveButtonDisabled(false);
    loadUsers(setUsers);
  };

  const handleNewUserError = () => {
    alert(t('users.userExists'));
    setSaveButtonDisabled(false);
  };

  const onSaveClick = () => {
    setSaveButtonDisabled(true);
    saveUser(inputValue, handleAcceptNewUser, handleNewUserError);
  };

  const onDeleteClick = (userId: string) => {
    setUserToDelete(userId);
  };

  const onFinishDeletingUser = () => {
    loadUsers(setUsers);
  };

  const onConfirm = () => {
    if (userToDelete) {
      deleteUser(userToDelete, onFinishDeletingUser);
    }
    setUserToDelete(null);
  };

  const handleKeyDown = ({ key }: KeyboardEvent<HTMLDivElement>) => {
    if (key === 'Enter' && validateEmail(inputValue) && !saveButtonDisabled) {
      onSaveClick();
    }
  };

  return (
    <Container>
      <StyledTypography variant="h4">{t('users.users')}</StyledTypography>
      <StyledInput
        label={t('users.addUser')}
        variant="outlined"
        placeholder={t('users.typeEmail')}
        type="email"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      />
      <AddUserButton
        color="success"
        variant="contained"
        onClick={onSaveClick}
        disabled={!validateEmail(inputValue) || saveButtonDisabled}
      >
        {t('users.save')}
      </AddUserButton>
      {users.map((userItem, index) => (
        <Fragment key={userItem.id}>
          {!!index && <Separator />}
          <UserItem>
            <UserId>{userItem.id}</UserId>
            {!userItem.role && (
              <DeleteButton onClick={() => onDeleteClick(userItem.id)}>
                {t('users.delete')}
              </DeleteButton>
            )}
          </UserItem>
        </Fragment>
      ))}
      {!!userToDelete && (
        <DeletePopup
          text={t('users.deleteUser')}
          label={userToDelete}
          onConfirm={onConfirm}
          onCancel={() => setUserToDelete(null)}
        />
      )}
    </Container>
  );
};

import ExcelJS from 'exceljs';
import { useTranslation } from 'react-i18next';

import { ExportExcelProps } from './exportExcel.types';
import { downloadExcelFile, createSumRowData, createColumnsTemplateData } from './exportExcel.utils';
import { StyledButton } from './exportExcel.styled';

export const ExportExcel = ({ reports, chosenDate }: ExportExcelProps) => {
  const { t } = useTranslation();
  const todayReports = reports.filter((report) => report.timestamp > chosenDate.unix());
  const reportsDate = chosenDate.format('DD-MM-YYYY');

  const exportExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet(reportsDate);

    sheet.columns = createColumnsTemplateData();

    todayReports.forEach((report) => {
      sheet.addRow(report).alignment = { horizontal: 'center' };
    });

    sheet.addRow(createSumRowData(todayReports)).alignment = { horizontal: 'center' };

    sheet.getRow(1).font = { bold: true };
    sheet.getRow(1).alignment = { horizontal: 'center' };
    sheet.getRow(todayReports.length + 2).font = { bold: true };

    sheet.getRow(todayReports.length + 2).border = {
      top: { style: 'thick' },
    };

    sheet.getColumn(1).border = {
      right: { style: 'thick' },
    };

    sheet.getCell(todayReports.length + 2, 1).border = {
      top: { style: 'thick' },
      right: { style: 'thick' },
    };

    workbook.xlsx.writeBuffer().then((data) => downloadExcelFile(data, reportsDate));
  };

  if (!todayReports.length) return null;

  return (
    <StyledButton
      onClick={exportExcelFile}
      variant="contained"
      color="success"
    >
      {t('exportExcel.export')}
    </StyledButton>
  );
};

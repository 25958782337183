import { useTranslation } from 'react-i18next';

import { Label } from '../../theme';

import {
  Container,
  Text,
  Buttons,
  OptionButton,
} from './deletePopup.styled';
import { DeletePopupProps } from './deletePopup.types';

export const DeletePopup = ({
  text,
  label,
  onConfirm,
  onCancel,
}: DeletePopupProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Text>{text}</Text>
      <Label>{label}</Label>
      <Buttons>
        <OptionButton variant="contained" onClick={onCancel}>
          {t('deletePopup.no')}
        </OptionButton>
        <OptionButton color="error" variant="contained" onClick={onConfirm}>
          {t('deletePopup.yes')}
        </OptionButton>
      </Buttons>
    </Container>
  );
};

import { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { DocumentData, DocumentSnapshot } from 'firebase/firestore';

import { getUser } from '../modules/login/login.api';
import { UserInterface } from '../interfaces';
import { Path } from '../enums';

import { Navbar } from './navbar/navbar.component';
import { Footer } from './footer/footer.component';
import { Loader } from './loader/loader.component';
import { AuthContext } from '../contexts/auth/auth.context';
import { LoaderContext } from './loader/loader.context';
import { Container, Wrapper } from './app.styled';
import { UserStorageKey } from './app.constants';

export const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userStorage = localStorage.getItem(UserStorageKey);
  const [user, setUser] = useState<UserInterface | null>(userStorage ? { id: userStorage } : null);
  const [displayLoader, setDisplayLoader] = useState(false);
  const [userFetched, setUserFetched] = useState(false);
  const [displayMainLoader, setDisplayMainLoader] = useState(true);
  const authValue = useMemo(() => ({ user, setUser }), [user]);
  const loaderValue = useMemo(() => ({ displayLoader, setDisplayLoader }), [displayLoader]);

  useEffect(() => {
    if (user && userFetched && location.pathname === Path.Home) {
      navigate(user.role ? Path.Reports : Path.AddReport);
    }
  }, [user, userFetched, location]);

  const onAcceptLogin = (userData: DocumentSnapshot<DocumentData>) => {
    setUser({
      id: userData.id,
      role: userData.data()?.role,
      pois: userData.data()?.pois,
    });

    if (location.pathname === Path.Home) {
      navigate(userData.data()?.role ? Path.Reports : Path.AddReport);
    }
  };

  const onLoginFail = () => {
    localStorage.removeItem(UserStorageKey);
    setUser(null);
  };

  const onLoginFinish = () => {
    setDisplayMainLoader(false);
  };

  useEffect(() => {
    setUserFetched(true);

    if (user && !userFetched) {
      getUser(user.id, onAcceptLogin, onLoginFail, onLoginFinish);
    } else if (!user) {
      navigate(Path.Login);
      setDisplayMainLoader(false);
    }
  }, [user, userFetched]);

  return (
    <Container>
      <LoaderContext.Provider value={loaderValue}>
        <AuthContext.Provider value={authValue}>
          <Navbar />
          <Wrapper>
            {displayMainLoader ? (
              <Loader />
            ) : (
              <Outlet />
            )}
            <Footer />
          </Wrapper>
        </AuthContext.Provider>
      </LoaderContext.Provider>
      {displayLoader && <Loader />}
    </Container>
  );
};

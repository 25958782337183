import styled from 'styled-components';

import { Label, PrimaryButton } from '../../theme';

export const Container = styled.div``;

export const StyledLabel = styled(Label)`
  margin-bottom: 20px;
`;

export const StyledButton = styled(PrimaryButton)`
  && {
    margin-top: 20px;
  }
`;

import { doc, setDoc, updateDoc } from 'firebase/firestore';
import dayjs from 'dayjs';

import { PoiResultInterface } from '../../interfaces';
import { Collection, db } from '../../firebase';

export const updateGoals = async (
  poiGoals: PoiResultInterface,
  createNewDocument: boolean,
  onFinish: () => void,
) => {
  const firstOfMonthString = dayjs(new Date()).startOf('month').unix().toString();
  const goalsDoc = doc(db, Collection.Goals, firstOfMonthString);

  if (createNewDocument) {
    await setDoc(goalsDoc, {
      [poiGoals.poiId.toLowerCase()]: poiGoals,
    });
  } else {
    await updateDoc(goalsDoc, {
      [poiGoals.poiId.toLowerCase()]: poiGoals,
    });
  }

  onFinish();
};

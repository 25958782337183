import { createContext } from 'react';

import { UserInterface } from '../../interfaces';

interface AuthContextInterface {
  user: UserInterface | null;
  setUser: (user: UserInterface | null) => void;
}

export const AuthContext = createContext<AuthContextInterface>({
  user: null,
  setUser: () => {},
});

import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';

import { convertReportsDataToArray } from '../../utils';
import { ReportInterface } from '../../interfaces';
import { Collection, db } from '../../firebase';

import { maxEditDays } from './reportsDetails.constants';

export const loadReports = async (onFinish: (reportsData: ReportInterface[]) => void) => {
  const reportsRef = collection(db, Collection.Reports);
  const reportQuery = query(
    reportsRef,
    where('timestamp', '>=', maxEditDays.unix()),
    orderBy('timestamp', 'desc'),
  );
  const reportsData = await getDocs(reportQuery);

  onFinish(convertReportsDataToArray(reportsData));
};

export const deleteReport = async (reportId: string, onFinish: () => void) => {
  await updateDoc(doc(db, Collection.Reports, reportId), {
    deleted: true,
  });

  const resultsData = await getDocs(collection(db, Collection.Results));

  resultsData.forEach(async (result) => {
    await deleteDoc(doc(db, Collection.Results, result.id));
  });

  onFinish();
};

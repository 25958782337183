import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Toolbar, MenuItem } from '@mui/material';

import { Color } from '../../theme';

export const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
`;

export const Logo = styled.img`
  width: 150px;
`;

export const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: ${Color.Gray};
`;

export const MenuLink = styled(Link)`
  display: block;
  text-decoration: none;
  color: ${Color.Gray};
`;

export const StyledMenuItem = styled(MenuItem)`
  && {
    font-size: 20px;
    line-height: 42px;
  }
`;

export const Separator = styled.hr`
  margin: 0;
`;

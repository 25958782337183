import styled from 'styled-components';

import { Color, PrimaryButton } from '../../theme';

export const Container = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${Color.Gray};
  color: ${Color.White};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 20px;
`;

export const Text = styled.p``;

export const Buttons = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  width: 200px;
`;

export const OptionButton = styled(PrimaryButton)`
  width: auto;
`;

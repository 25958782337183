import styled from 'styled-components';

import { ReportTable as TableBase } from '../../components/reportTable/reportTable.component';
import { Input, PrimaryButton } from '../../theme';

export const Container = styled.div``;

export const ReportTable = styled(TableBase)`
  margin-top: 10px;
`;

export const Inputs = styled.div``;

export const Bold = styled.b``;

export const StyledInput = styled(Input)`
  && {
    margin-top: 15px;
  }
`;

export const StyledButton = styled(PrimaryButton)`
  && {
    margin-top: 20px;
  }
`;

export const Separator = styled.hr`
  margin: 30px 0;
`;

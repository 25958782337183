import {
  ChangeEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { AuthContext } from '../../contexts/auth/auth.context';
import { loadGoals, loadPoiList } from '../../api/common';
import { ReportValuesKeys } from '../../constants';
import { convertReportValuesToNumbers, initFormValues } from '../../utils';
import { ResultDataInterface, PoiInterface, ReportInterface } from '../../interfaces';
import { Path } from '../../enums';
import { Dropdown, Label } from '../../theme';
import { loadReports, loadResults } from '../reports/reports.api';

import {
  Container,
  ReportTable,
  Inputs,
  Bold,
  StyledInput,
  StyledButton,
  Separator,
} from './addReport.styled';
import { addReport } from './addReport.api';

export const AddReport = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false);
  const [pois, setPois] = useState<PoiInterface[]>([]);
  const filteredPois = useMemo(() => (
    pois.filter((poi) => (user?.pois ? user?.pois?.includes(poi.id) : true))
  ), [user, pois]);
  const [reports, setReports] = useState<ReportInterface[]>([]);
  const [pos, setPos] = useState('');
  const [values, setValues] = useState(initFormValues);
  const [goals, setGoals] = useState<ResultDataInterface>();
  const [results, setResults] = useState<ResultDataInterface>();
  const todayDate = dayjs(new Date().setHours(0, 0, 0, 0));

  useEffect(() => {
    loadPoiList(setPois);
    loadReports(todayDate, (reportArray) => {
      setReports(reportArray);
      loadResults(todayDate, setResults);
    });
    loadGoals(todayDate, setGoals);
  }, []);

  useEffect(() => {
    if (filteredPois.length === 1) {
      setPos(filteredPois[0].id);
    }
  }, [filteredPois]);

  const handlePosChange = ({ target }: SelectChangeEvent<unknown>) => {
    setPos(target.value as string);
  };

  const handleInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [target.name]: Number(target.value) < 0 ? 0 : target.value,
    });
  };

  const sendReport = () => {
    const newReport = {
      ...convertReportValuesToNumbers(values),
      poiId: pos,
      timestamp: dayjs(new Date()).unix(),
      user: user?.id || '',
    };

    setSendButtonDisabled(true);

    if (user?.id) {
      addReport(newReport, () => {
        navigate(Path.Reports);
      });
    } else {
      alert(t('addReport.logInAgain'));
      navigate(Path.Home);
    }
  };

  return (
    <Container>
      <Label>{t('addReport.todayReports')}</Label>
      <ReportTable
        reports={reports}
        chosenDate={todayDate}
        goals={goals}
        results={results}
      />
      <Separator />
      <Inputs>
        {filteredPois.length === 1 ? (
          <Label>
            {t('editReports.poi')}
            {' '}
            <Bold>{filteredPois[0].name}</Bold>
          </Label>
        ) : (
          <Dropdown
            value={pos}
            onChange={handlePosChange}
            displayEmpty
            disabled={!pois.length}
          >
            <MenuItem value="">
              <em>{t('addReport.poi')}</em>
            </MenuItem>
            {filteredPois.map((poi) => (
              <MenuItem key={poi.id} value={poi.id}>
                <b>{poi.name.toUpperCase()}</b>
              </MenuItem>
            ))}
          </Dropdown>
        )}
        {!!pos && ReportValuesKeys.map((key) => (
          <StyledInput
            key={key}
            label={t(`addReport.${key}`)}
            variant="outlined"
            placeholder={t('addReport.inputPlaceholder')}
            type="number"
            value={values[key]}
            name={key}
            onChange={handleInputChange}
            onWheel={(event) => event.target instanceof HTMLElement && event.target.blur()}
            InputProps={{
              inputProps: { min: 0 },
            }}
          />
        ))}
      </Inputs>
      <StyledButton
        variant="contained"
        color="success"
        disabled={!pos || Object.values(values).some((value) => !value) || sendButtonDisabled}
        onClick={sendReport}
      >
        {t('addReport.sendReport')}
      </StyledButton>
    </Container>
  );
};

import { createContext } from 'react';

interface LoaderContextInterface {
  displayLoader: boolean;
  setDisplayLoader: (state: boolean) => void;
}

export const LoaderContext = createContext<LoaderContextInterface>({
  displayLoader: false,
  setDisplayLoader: () => {},
});

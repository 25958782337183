import {
  doc,
  DocumentData,
  DocumentSnapshot,
  getDoc,
} from 'firebase/firestore';

import { Collection, db } from '../../firebase';

export const getUser = async (
  value: string,
  acceptCb: (userData: DocumentSnapshot<DocumentData>) => void,
  rejectCb: () => void,
  finishCb: () => void,
) => {
  const userRef = doc(db, Collection.Users, value);
  const userData = await getDoc(userRef);

  if (userData.exists() && !userData.data().deleted) {
    acceptCb(userData);
  } else {
    rejectCb();
  }

  finishCb();
};

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/pl';

import { UpdatePopup } from './app/updatePopup/updatePopup.component';
import { AddReport } from './modules/addReport/addReport.component';
import { Reports } from './modules/reports/reports.component';
import { ReportsDetails } from './modules/reportsDetails/reportsDetails.component';
import { EditReport } from './modules/editReport/editReport.component';
import { AddGoals } from './modules/addGoals/addGoals.component';
import { Users } from './modules/users/users.component';
import { Login } from './modules/login/login.component';
// import { Password } from './modules/password/password.component';
import { NotFound } from './modules/notFound/notFound.component';
import { App } from './app/app.component';
import { Path } from './enums';
import { appLocale } from './constants';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './i18n';
import './index.css';
import reportWebVitals from './reportWebVitals';

dayjs.locale(appLocale);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={appLocale}>
      <BrowserRouter>
        <Routes>
          <Route path={Path.Home} element={<App />}>
            <Route path={Path.AddReport} element={<AddReport />} />
            <Route path={Path.Reports} element={<Reports />} />
            <Route path={Path.EditReports} element={<ReportsDetails />} />
            <Route path={`${Path.EditReports}/:reportId`} element={<EditReport />} />
            <Route path={Path.Goals} element={<AddGoals />} />
            <Route path={Path.Users} element={<Users />} />
            <Route path={Path.Login} element={<Login />} />
            {/* <Route path="pass" element={<Password />} /> */}
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </LocalizationProvider>
  </React.StrictMode>,
);

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    registration.waiting?.postMessage({ type: 'SKIP_WAITING' });

    root.render(
      <React.StrictMode>
        <UpdatePopup />
      </React.StrictMode>,
    );
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

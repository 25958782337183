import {
  addDoc,
  getDoc,
  updateDoc,
  doc,
  collection,
} from 'firebase/firestore';
import dayjs from 'dayjs';

import { ReportInterface, PoiResultInterface } from '../../interfaces';
import { Collection, db } from '../../firebase';

import { updateResultsData } from './addReport.utils';

export const addReport = async (newReport: ReportInterface, onFinish: () => void) => {
  const poiId = newReport.poiId.toLowerCase();
  const firstOfMonth = dayjs.unix(newReport.timestamp).startOf('month').unix();
  const resultsRef = doc(db, Collection.Results, firstOfMonth.toString());
  const reportRef = await addDoc(collection(db, Collection.Reports), newReport);
  const resultsDoc = await getDoc(resultsRef);

  if (resultsDoc.exists()) {
    const poiResults: PoiResultInterface = resultsDoc.data()[poiId];

    await updateDoc(resultsRef, {
      [poiId]: updateResultsData(poiResults, newReport),
    });
  }

  if (reportRef) {
    onFinish();
  }
};

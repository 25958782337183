import { createTheme } from '@mui/material';

export const muiDatePickerTheme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
  },
});

import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

import { AuthContext } from '../../contexts/auth/auth.context';
import { UserRole, Path } from '../../enums';
import { ReportInterface } from '../../interfaces';

import { Report } from './components/report/report.component';
import { loadReports, deleteReport } from './reportsDetails.api';
import { Container } from './reportsDetails.styled';

export const ReportsDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [reports, setReports] = useState<ReportInterface[]>([]);
  const canEditReports = user?.role === UserRole.Moderator || user?.role === UserRole.Superadmin;

  useEffect(() => {
    if (canEditReports) {
      loadReports(setReports);
    } else {
      navigate(Path.Home);
    }
  }, []);

  const onFinishDeletingReport = () => {
    loadReports(setReports);
  };

  const onDeleteReport = (reportId: string | undefined) => {
    if (reportId) {
      deleteReport(reportId, onFinishDeletingReport);
    }
  };

  return (
    <Container>
      <Typography variant="h4">{t('editReports.editReports')}</Typography>
      {reports.map((report: ReportInterface) => (
        <Report key={report.id} data={report} onDelete={() => onDeleteReport(report.id)} />
      ))}
    </Container>
  );
};

import { useTranslation } from 'react-i18next';

import { ReportValuesKeys } from '../../constants';
import { Error } from '../../theme';

import { ReportTableProps } from './reportTable.types';
import { Container, TableWrapper, Table } from './reportTable.styled';

export const ReportTable = ({
  className,
  reports,
  chosenDate,
  results = {},
  goals = {},
}: ReportTableProps) => {
  const { t } = useTranslation();
  const todayReports = reports.filter(
    (report) => report.timestamp > chosenDate.unix() && report.timestamp < chosenDate.endOf('day').unix(),
  );

  if (!todayReports.length) {
    return <Error>{t('reports.noReports')}</Error>;
  }

  return (
    <Container className={className}>
      <TableWrapper>
        <Table>
          <thead>
            <tr>
              <th>{t('reports.poi')}</th>
              {ReportValuesKeys.map((key) => (
                <th key={key}>{t(`reports.${key}`)}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {todayReports.map((report) => {
              const poiId = report.poiId.toLowerCase();
              const poiResult = results[poiId];
              const poiGoal = goals[poiId];

              return (
                <tr key={report.id}>
                  <td>{report.poiId}</td>
                  {ReportValuesKeys.map((key, index) => (
                    <td
                      key={key}
                      className={`
                        ${poiGoal?.[key] && (poiResult?.[key] ?? 0) >= poiGoal[key] ? 'green' : ''}
                        ${index < 4 && poiGoal?.[key] && (poiResult?.[key] ?? 0) < poiGoal[key] ? 'red' : ''}
                      `}
                    >
                      {report[key]}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td>{todayReports.length}</td>
              {ReportValuesKeys.map((key) => (
                <td key={key}>
                  {todayReports.reduce((acc, report) => acc + (report[key] ?? 0), 0)}
                </td>
              ))}
            </tr>
          </tfoot>
        </Table>
      </TableWrapper>
    </Container>
  );
};

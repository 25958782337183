import styled from 'styled-components';

import { Input, PrimaryButton } from '../../theme';

export const Container = styled.div``;

export const Header = styled.div`
  margin: 20px 0;
`;

export const Inputs = styled.div``;

export const StyledInput = styled(Input)`
  && {
    margin-top: 15px;
  }
`;

export const StyledButton = styled(PrimaryButton)`
  && {
    margin-top: 20px;
  }
`;

export const DatePickerWrapper = styled.div`
  margin-bottom: 15px;
`;

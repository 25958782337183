import { DocumentData, QuerySnapshot } from 'firebase/firestore';

import { ReportInterface } from '../interfaces';

export const convertReportsDataToArray = (reportsData: QuerySnapshot<DocumentData>) => {
  const reportArray: ReportInterface[] = [];

  reportsData.forEach((report) => {
    const reportData = report.data() as ReportInterface;

    if (!reportData.deleted) {
      reportArray.push({
        id: report.id,
        ...reportData,
      });
    }
  });

  return reportArray;
};

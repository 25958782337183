import {
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from 'firebase/firestore';

import { UserInterface } from '../../interfaces';
import { Collection, db } from '../../firebase';

export const loadUsers = async (onFinish: (users: UserInterface[]) => void) => {
  const userData = await getDocs(collection(db, Collection.Users));
  const users: UserInterface[] = [];

  userData.forEach((user) => {
    if (!user.data().deleted) {
      users.push({ id: user.id, role: user.data().role });
    }
  });

  onFinish(users);
};

export const saveUser = async (
  userId: string,
  onAccept: () => void,
  onError: () => void,
) => {
  const userRef = doc(db, Collection.Users, userId);
  const userData = await getDoc(userRef);

  if (userData.exists()) {
    onError();
  } else {
    await setDoc(doc(db, Collection.Users, userId), {});
    onAccept();
  }
};

export const deleteUser = async (userId: string, onFinish: () => void) => {
  await updateDoc(doc(db, Collection.Users, userId), {
    deleted: true,
  });

  onFinish();
};

import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from 'firebase/firestore';

import { ReportInterface } from '../../interfaces';
import { Collection, db } from '../../firebase';

export const loadReport = async (
  reportId: string,
  onFinish: (reportsData: ReportInterface) => void,
) => {
  const reportRef = doc(db, Collection.Reports, reportId);
  const reportDoc = await getDoc(reportRef);

  if (reportDoc.exists()) {
    onFinish(reportDoc.data() as ReportInterface);
  }
};

export const editReport = async (newData: ReportInterface, onFinish: () => void) => {
  const reportRef = doc(db, Collection.Reports, newData.id!);
  await updateDoc(reportRef, { ...newData });

  const resultsData = await getDocs(collection(db, Collection.Results));

  resultsData.forEach(async (result) => {
    await deleteDoc(doc(db, Collection.Results, result.id));
  });
  onFinish();
};

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Color, Label as LabelBase } from '../../../../theme';

export const Container = styled.div`
  margin-top: 20px;
  background-color: ${Color.LightGray};
  padding: 10px;
`;

export const Text = styled.p`
  margin: 0 0 10px;

  .highlighted {
    color: ${Color.Green};
  }
`;

export const Bold = styled.b``;

export const Label = styled(LabelBase)`
  margin-bottom: 10px;
`;

export const Options = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
`;

export const DeleteButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  color: ${Color.Red};
  font-style: italic;
  cursor: pointer;
`;

export const StyledLink = styled(Link)`
  font-style: italic;
  text-decoration: none;
`;

import {
  ChangeEvent,
  KeyboardEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DocumentData, DocumentSnapshot } from 'firebase/firestore';

import { AuthContext } from '../../contexts/auth/auth.context';
import { LoaderContext } from '../../app/loader/loader.context';
import { UserStorageKey } from '../../app/app.constants';
import { Path } from '../../enums';
import { Input } from '../../theme';

import {
  Container,
  StyledLabel,
  StyledButton,
} from './login.styled';
import { getUser } from './login.api';

export const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, setUser } = useContext(AuthContext);
  const { setDisplayLoader } = useContext(LoaderContext);
  const [inputValue, setInputValue] = useState('');
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (user) {
      navigate(Path.Home);
    }
  }, []);

  const handleInputChange = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
    setInputValue(currentTarget.value);
  };

  const onAcceptLogin = (userData: DocumentSnapshot<DocumentData>) => {
    localStorage.setItem(UserStorageKey, userData.id);
    setUser({
      id: userData.id,
      role: userData.data()?.role,
      pois: userData.data()?.pois,
    });
    navigate(userData.data()?.role ? Path.Reports : Path.AddReport);
  };

  const onLoginFail = () => {
    alert(t('login.incorrectEmail'));
  };

  const onFinishLogin = () => {
    setDisplayLoader(false);
    setDisableButton(false);
  };

  const logIn = () => {
    setDisableButton(true);
    setDisplayLoader(true);
    getUser(inputValue, onAcceptLogin, onLoginFail, onFinishLogin);
  };

  const handleKeyDown = ({ key }: KeyboardEvent<HTMLDivElement>) => {
    if (key === 'Enter' && !!inputValue && !disableButton) {
      logIn();
    }
  };

  return (
    <Container>
      <StyledLabel>{t('login.label')}</StyledLabel>
      <Input
        type="email"
        label={t('login.email')}
        variant="outlined"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      />
      <StyledButton
        variant="contained"
        color="success"
        disabled={!inputValue || disableButton}
        onClick={logIn}
      >
        {t('login.continue')}
      </StyledButton>
    </Container>
  );
};

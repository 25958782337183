import styled from 'styled-components';

import { Color } from './colors';

export const Label = styled.p`
  font-size: 20px;
  margin: 0;
`;

export const Error = styled.p`
  font-size: 16px;
  color: ${Color.Red};
`;

export const Small = styled.small``;

import {
  Container,
  Separator,
  ExternalLink,
  Text,
} from './footer.styled';

export const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Container>
      <Separator />
      <Text>
        Created by
        {' '}
        <ExternalLink
          href="https://itpulse.pl"
          target="_blank"
          rel="noopener noreferrer"
        >
          IT Pulse
        </ExternalLink>
        {' '}
        ©
        {' '}
        {currentYear}
      </Text>
    </Container>
  );
};

import { useTranslation } from 'react-i18next';

import { Container, Label, Button } from './updatePopup.styled';

export const UpdatePopup = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Label>{t('updatePopup.label')}</Label>
      <Button color="success" variant="contained" onClick={() => window.location.reload()}>
        {t('updatePopup.refresh')}
      </Button>
    </Container>
  );
};

import {
  collection,
  getDocs,
  getDoc,
  setDoc,
  doc,
  query,
  where,
  QuerySnapshot,
  DocumentData,
} from 'firebase/firestore';
import { Dayjs } from 'dayjs';

import { convertReportsDataToArray } from '../../utils';
import { ReportInterface, ResultDataInterface } from '../../interfaces';
import { Collection, db } from '../../firebase';

import { convertResultsDataToObject } from './reports.utils';

export const loadReports = async (
  chosenDate: Dayjs,
  onFinish: (reportsData: ReportInterface[]) => void,
) => {
  const firstOfMonth = chosenDate.startOf('month').unix();
  const endOfMonth = chosenDate.endOf('month').unix();
  const endOfDay = chosenDate.endOf('day').unix();
  const reportsRef = collection(db, Collection.Reports);
  const resultsRef = doc(db, Collection.Results, firstOfMonth.toString());
  const resultsDoc = await getDoc(resultsRef);
  let reportsData: QuerySnapshot<DocumentData>;
  let reports: ReportInterface[];

  if (resultsDoc.exists()) {
    const reportQuery = query(
      reportsRef,
      where('timestamp', '>=', chosenDate.unix()),
      where('timestamp', '<', endOfDay),
    );
    reportsData = await getDocs(reportQuery);
    reports = [...convertReportsDataToArray(reportsData)];
  } else {
    const reportQuery = query(
      reportsRef,
      where('timestamp', '>=', firstOfMonth),
      where('timestamp', '<', endOfMonth),
    );
    reportsData = await getDocs(reportQuery);
    reports = [...convertReportsDataToArray(reportsData)];

    const poiData = await getDocs(collection(db, Collection.Pois));
    await setDoc(resultsRef, convertResultsDataToObject(poiData, reports));
  }

  onFinish(reports);
};

export const loadResults = async (
  chosenDate: Dayjs,
  onFinish: (resultsData: ResultDataInterface) => void,
) => {
  const firstOfMonth = chosenDate.startOf('month').unix();
  const resultsRef = doc(db, Collection.Results, firstOfMonth.toString());
  const resultsDoc = await getDoc(resultsRef);

  if (resultsDoc.exists()) {
    onFinish(resultsDoc.data());
  }
};
